import React from 'react';

function PointsLoader() {
  const mystyle = {
    backgroundColor: "#bf1b26",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    position: "absolute",
    top: "33px",
    animationTimingFunction: "cubic-bezier(0, 1, 1, 0)"
  };

  const containerStyle = {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "80px"
  };

  const div1Style = {
    ...mystyle,
    left: "8px",
    animation: "lds-ellipsis1 0.6s infinite"
  };

  const div2Style = {
    ...mystyle,
    left: "8px",
    animation: "lds-ellipsis2 0.6s infinite"
  };

  const div3Style = {
    ...mystyle,
    left: "32px",
    animation: "lds-ellipsis2 0.6s infinite"
  };

  const div4Style = {
    ...mystyle,
    left: "56px",
    animation: "lds-ellipsis3 0.6s infinite"
  };

  return (
    <div className="text-center w-full">
      <div style={containerStyle}>
        <div style={div1Style}></div>
        <div style={div2Style}></div>
        <div style={div3Style}></div>
        <div style={div4Style}></div>
      </div>
      <style jsx>{`
        @keyframes lds-ellipsis1 {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
        @keyframes lds-ellipsis2 {
          0% {
            transform: translate(0, 0);
          }
          100% {
            transform: translate(24px, 0);
          }
        }
        @keyframes lds-ellipsis3 {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(0);
          }
        }
      `}</style>
    </div>
  );
}

export default PointsLoader;