import React from "react";
import NavigateButton from "../../../components/buttonsNavigate";
import {
  FaArrowLeft,
  FaBalanceScale,
  FaCartArrowDown,
  FaCheckCircle,
  FaExchangeAlt,
  FaHistory,
  FaListUl,
  FaSearch,
  FaCheck,
  FaShoppingCart,
  FaSyncAlt,
} from "react-icons/fa";
import { FaMapMarkerAlt, FaEdit } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { LiaTruckPickupSolid } from "react-icons/lia";
import { TbTransfer } from "react-icons/tb";
import { FaWarehouse } from "react-icons/fa";

import { LuBoxes } from "react-icons/lu";
import { BsDatabaseFillCheck } from "react-icons/bs";
import {
  FaBoxOpen,
  FaProductHunt,
  FaRegFileLines,
  FaToolbox,
  FaFile,
} from "react-icons/fa6";
import { HiStatusOffline } from "react-icons/hi";

const WareHouse = () => {
  const colors = [
    "bg-[#BF1B26]",
    "bg-[#0D6E99]",
    "bg-[#5E2D79]",
    "bg-[#4CAF50]",
    "bg-[#333333]",
    "bg-[#CCCCCC]",
    "bg-[#FF5733]",
    "bg-[#FFC300]",
    "bg-[#581845]",
    "bg-[#FF851B]",
    "bg-[#7D3C98]",
    "bg-[#01A9DB]",
    "bg-[#7FF050]",
    "bg-[#FF007F]",
    "bg-[#ADD8E6]",
    "bg-[#7EE050]",
  ];
  var host = window.location.host;

  const history = useNavigate();
  return (
    <>
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white z-50">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl    my-auto">Warehouse</h2>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-2 container gap-x-6 gap-y-4">
        <>
          <NavigateButton
            icon={<FaWarehouse />}
            name={"Products"}
            color={colors[15]}
            onclick={() => history("WarehouseProduct")}
          />

          <NavigateButton
            icon={<LuBoxes />}
            name={"Bin"}
            color={colors[1]}
            onclick={() => history("changeproductaddress")}
          />
          <NavigateButton
            icon={<TbTransfer />}
            name={"Warehouse Transfer"}
            color={"bg-[rgb(145,170,0)]"}
            onclick={() => history("warehousetransfer")}
          />
          <NavigateButton
            icon={<FaSyncAlt />}
            name={"Change Status"}
            color={"bg-[rgb(252,186,3)] bg-opacity-90"}
            onclick={() => history("ChangeStatusWarehouse")}
          />
          {/* <NavigateButton
            icon={<FaExchangeAlt />}
            name={"Change Upc"}
            color={"bg-[rgb(252,0,3)] bg-opacity-90"}
            onclick={() => history("ChangeUpcWarehouse")}
          /> */}
          {/* <NavigateButton
            icon={<FaBalanceScale />}
            name={"Change Bin / Reference Quantity"}
            color={"bg-[rgb(0,186,3)] bg-opacity-90"}
            onclick={() => history("ChangeUpcQuantityWarehouse")}
          /> */}

          <NavigateButton
            icon={<FaToolbox />}
            name={"Manage Bin / Reference Quantity"}
            color={"bg-[rgb(252,0,255)] bg-opacity-90"}
            onclick={() => history("ManageUpcQuantityWarehouse")}
          />
          <NavigateButton
            icon={<FaSearch />}
            name={"Check Address "}
            color={"bg-[rgb(0,50,0)] bg-opacity-90"}
            onclick={() => history("CheckAddressWarehouse")}
          />
          <NavigateButton
            icon={<FaSearch />}
            name={"Check Reference "}
            color={"bg-[rgb(90,40,3)] bg-opacity-90"}
            onclick={() => history("CheckReferenceWarehouse")}
          />
          {/* <NavigateButton
            icon={<FaShoppingCart />}
            name={"Stand Restock"}
            arname={"إعادة التعبئة "}
            color={"bg-[rgb(300,40,3)] bg-opacity-90"}
            onclick={() => history("standrestock")}
          /> */}
          {host === "pda.ishtari.com.gh" && (
            <NavigateButton
              icon={<FaBoxOpen />}
              name={"Receive Product Quantity "}
              color={"bg-[rgb(204,0,204)] bg-opacity-90"}
              onclick={() => history("ReceiveProductQty")}
            />
          )}
          <NavigateButton
            icon={<FaFile />}
            name={"Change Reference"}
            arname={"تغيير المرجع "}
            color={colors[4]}
            onclick={() => history("ChangeReference")}
          />
          {/* <NavigateButton
            icon={<FaRegFileLines />}
            name={"Refill Bin Warehouse "}
            color={"bg-[rgb(0,204,0)] bg-opacity-90"}
            onclick={() => history("refillbinwarehouse")}
          /> */}
        </>
      </div>
    </>
  );
};

export default WareHouse;
