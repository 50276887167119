import React, { useRef, useState } from "react";
import { FaArrowLeft, FaSearch ,FaTrash} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
const WarehouseProduct = () => {
    const { playErrorSound, playSuccessSound } = PlaySounds();

    const history = useNavigate();
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
    const [error, setError] = useState(null);
    const OrderID = useRef(null);
    const ProductID = useRef(null);
    const [systemQuantity, setSystemQuantity] = useState(null);
    const [message, setMessage] = useState("");
    const [showMessgSucc, setShowMessageSucc] = useState(false);
    const [showMessgErr, setShowMessageErr] = useState(false);
    const [ProductInfo, setProductInfo] = useState([]);
    const [wareHouseStatus,setWareHouseStatus]= useState('');
    const [fromwarehouse, setfromwarehouse] = useState(null);
    const [towarehouse, settowarehouse] = useState(null);

    const getOrders = async (e) => {
        e.preventDefault();
        setLoadingSearch(true);
        setError(null);
        try {
          const url = `${buildLink(`getTransferOrderProducts`)}&order_id=${
            OrderID.current.value }`;
    
          const response = await axios.get(url);
          if (response.data.success) {
            setMessage("Order with id: " + OrderID.current.value + " Was Found");
            setShowMessageSucc(true);
            playSuccessSound();
            setWareHouseStatus(response.data.status);
            setTimeout(() => setShowMessageSucc(false), 10000);
            setProductInfo(response.data.products);
            setfromwarehouse(response.data.from_warehouse);
            settowarehouse(response.data.to_warehouse);
            ProductID.current.focus();
          } else {
            setMessage(response.message);
            setShowMessageErr(true);
            setTimeout(() => setShowMessageErr(false), 10000);
            playErrorSound();
            OrderID.current.value = "";
          }
        } catch (e) {
          setMessage(e.response?.data?.message);
          setShowMessageErr(true);
          setTimeout(() => setShowMessageErr(false), 10000);
          playErrorSound();
          OrderID.current.value = "";
        } finally {
          setLoadingSearch(false);
        }
      };
  
      const handleTrashClick = () => {
        OrderID.current.value = "";
        OrderID.current.focus();
      };
    return (
        <>
            {/* Header */}
            <div className="mb-5 sticky top-0 flex flex-col bg-white z-50">
                <div className="w-full bg-dbase h-16">
                    <div className="container px-3 relative flex justify-between w-full text-white h-full">
                        <div className="flex gap-7 flex-row justify-start">
                            <button onClick={() => history(-1)}>
                                <FaArrowLeft />
                            </button>
                            <h2 className="text-xl my-auto">Check Order</h2>
                        </div>
                    </div>
                </div>
            </div>

    
            <div className="container">
              <form onSubmit={getOrders}>
          <div className="flex flex-col">
            <div className="mb-1">Order ID</div>
            <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
            <div className=" relative flex flex-row border-dblack rounded-lg text-center w-full gap-3">
  <input
    ref={OrderID}
    type="text"
    className="border border-dblack rounded-lg block p-2 flex-grow text-start w-full h-12 pr-10" // Add pr-10 to create space for the icon
    placeholder="Order ID"
  />
  <FaTrash
    onClick={() => handleTrashClick()}
    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
  />
</div>

              <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
                {loadingSearch ? <Loader /> : <FaSearch />}
              </button>
            </div>
          </div>
        </form>
            </div>


  
                <div className="overflow-x-auto mt-5 px-3 md:px-12 lg:px-32">
                    <table className="min-w-full bg-white border border-dgreySeller">
                        <thead>
                            <tr className="bg-dgrey text-dblackk">
                            <th className="border px-4 py-2">Product Image</th>
                                <th className="border px-4 py-2">Product Name</th>
                                <th className="border px-4 py-2">Product SKU</th>
                                <th className="border px-4 py-2">Product Option</th>
                                <th className="border px-4 py-2">Product Status</th>
                                <th className="border px-4 py-2">Product Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                        {ProductInfo?.map((product, index) => (
                            <tr 
                                key={index} 
                                style={{
                                    backgroundColor: wareHouseStatus !== product.status ? '	#FFC4CA ' : '',
                                    }}
                                   >
                                <td className="border px-4 py-2 text-center">
                                    <img src={product.image} />
                                </td>
                                <td className="border px-4 py-2 text-center">
                            {(() => {
                                const parts = product.name.split(' ');
                                const maxLines = 5;

                                if (parts.length > maxLines) {
                                return parts.slice(0, maxLines).join(' ') + '...';
                                }

                                return parts.join(' ');
                            })()}
                            </td>
                                <td className="border px-4 py-2 text-center">{product.sku}</td>
                                <td className="border px-4 py-2 text-center">{product.option_value_name}</td>
                                <td className="border px-4 py-2 text-center">{product.status}</td>
                                <td className="border px-4 py-2 text-center">{product.quantity}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>


            <MessageAlert
                message={message}
                type={showMessgErr ? "err" : "succ"}
                showMessg={showMessgErr || (showMessgSucc && true)}
            />
        </>
    );
};

export default WarehouseProduct;
