import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheckCircle, FaSearch, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import buildLink from "../../../../urls";
import axios from "axios";
import { CompanyContext } from "../../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../../components/loader";
import MessageAlert from "../../../../components/messageAlert";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { PlaySounds } from "../../../../functions/playSounds";
import hocStarredPage from "../../../../components/hocStarredPage";
import { BsClockHistory } from "react-icons/bs";
import Cookies from "js-cookie";
import Select from "react-select";

const UpcRestockv2 = () => {
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const history = useNavigate();
  const codePr = useRef(null);
  const currentUpc = useRef(null);
  const restockQty = useRef(null);
  const [error, setError] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const commentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [productInf, setProductInf] = useState(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);

  const handleInputChange = (e) => {
    // Check if the input field is empty
    setIsInputEmpty(e.target.value === "");
  };

  const handleWarehouseChange = (selectedOption) => {
    const warehouseId = selectedOption ? selectedOption.value : null;
    setSelectedWarehouseId(warehouseId);

    // Save to cookies for 1 day
    if (warehouseId) {
      Cookies.set("selectedWarehousecheckbin", warehouseId, { expires: 1 });
    } else {
      Cookies.remove("selectedWarehousecheckbin");
    }
  };

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();
    const savedWarehouse = Cookies.get("selectedWarehousecheckbin");
    if (savedWarehouse) {
      setSelectedWarehouseId(savedWarehouse);
    }
  }, []);

  const searchProduct = async (e) => {
    setLoadingSearch(true);
    e.preventDefault();

    const item = codePr.current.value;
    const warehouseID = selectedWarehouseId; 

    try {
      let url;
      if (host.startsWith("localhost") || host === "pda.ishtari.com") {
        url =
          buildLink("getWarehouseProductDetails") +
          `&product=${item}` +
          `&warehouse_id=${warehouseID}`;
      } else {
        url =
        buildLink("productInfo") +
        `&item=${item}&v2=true` +
        `&user_id=${stateCom.userID}`;
      }

      await axios.get(url).then((response) => {
        if (response.data.success) {
          setProductInf(response.data.product);
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          playSuccessSound();
        }
      });
      setLoadingSearch(false);
    } catch (e) {
      playErrorSound();
      setLoadingSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingSearch(false);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const checkUpcOrBinIdMatch = async (data) => {
    setLoadingCheck(true);
  
   const   currentUpcValue = currentUpc.current.value;
       


    if (
      currentUpcValue === productInf.upc ||
      currentUpcValue === productInf.bin_id
    ) {
      restockQty.current.focus();
      console.log("Match found!");
      setLoadingCheck(false);
      return;
    }

    // If no match found or data is incomplete, reset fields and show error
    setProductInf(null);
    codePr.current.value = "";
      currentUpc.current.value = "";    
    restockQty.current.value = "";
    commentRef.current.value = "";
    playErrorSound();
    setMessage("Product information not found or incomplete.");
    setShowMessageErr(true);
    setTimeout(() => {
      setShowMessageErr(false);
    }, 3000);
    setLoadingCheck(false);
    codePr.current.focus();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };
  var host = window.location.host;

  const restockUpc = async (e) => {
    if(host === "pda.ishtari.com.gh"){
     checkUpcOrBinIdMatch(e);
    }
  
    e.preventDefault();
    const userID = getUserIdFromLocalStorage();
    const product_id = codePr.current.value;
    let id="45";
    if(host === "pda.ishtari.com.gh"){
      id = currentUpc.current.value;
    }
    
    const user_id = userID;
    const restock_quantity = restockQty.current.value;
    const comment = commentRef.current.value;

    const obj = JSON.stringify({
      product_id,
      id,
      user_id,
      restock_quantity,
      comment,
    });
    setLoading(true);

    try {
      const url = buildLink("restockUPC") + `&v2=true`;
      const response = await axios.post(url, JSON.parse(obj));

      if (response.data && response.data.success) {
        playSuccessSound();
        setMessage(response.data.message);
        setShowMessageSucc(true);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        if(host === "pda.ishtari.com.gh"){
          currentUpc.current.value = "";
        }
        restockQty.current.value = "";
        commentRef.current.value = "";
        codePr.current.value = "";
      } else {
        playErrorSound();
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
      }
    } catch (error) {
      playErrorSound();
      setMessage("Data not found");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    codePr.current.focus();
  }, []);

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  const handleHistoryClick = () => {
    const codePrValue = codePr.current.value;
    history(`restockhistory?codePr=${codePrValue}`);
  };


  return (
    <>
      <div className="  h-full bg-white">
        <div className=" mb-5 sticky top-0 flex flex-col bg-white z-40">
          <div className=" w-full bg-dbase h-16">
            <div className=" container px-3  relative flex justify-between w-full text-white  h-full my-auto">
              <div className=" flex  gap-7 flex-row justify-start">
                <button onClick={() => history(-1)}>
                  <FaArrowLeft />
                </button>
                {(host.startsWith("localhost") ||
                  host === "pda.ishtari.com") && (
                  <h2 className=" text-xl    my-auto">Stand Restock</h2>
                )}
                {host === "pda.ishtari.com.gh" && (
                  <h2 className=" text-xl    my-auto">UPC / BIN Restock</h2>
                )}
              </div>
              <button onClick={() => handleHistoryClick()}>
                <BsClockHistory className="text-2xl mt-1" />
              </button>
            </div>
          </div>
        </div>

        <div className="container flex flex-col gap-5">
          {(host.startsWith("localhost") || host === "pda.ishtari.com") && (
            <Select
              placeholder="Select Warehouse"
              required
              onChange={handleWarehouseChange}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "7px",
                  borderColor: state.isFocused ? "grey" : "grey",
                  padding: "8px 5px",
                }),
              }}
              options={
                warehouses.length > 0
                  ? warehouses.map((warehouse) => ({
                      value: warehouse.warehouse_id,
                      label: warehouse.warehouse_name,
                    }))
                  : []
              }
              value={
                selectedWarehouseId
                  ? warehouses
                      .map((warehouse) => ({
                        value: warehouse.warehouse_id,
                        label: warehouse.warehouse_name,
                      }))
                      .find((option) => option.value === selectedWarehouseId)
                  : null
              }
              className="w-full"
            />
          )}
          <form onSubmit={(e) => searchProduct(e)}>
            <div className="flex flex-row justify-center gap-3">
              <div className="relative flex-1">
                <input
                  ref={codePr}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Product ID Or Sku"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingSearch ? <Loader /> : <FaSearch />}{" "}
              </button>
            </div>
          </form>
          {/* <form onSubmit={(e) =>  e.preventDefault(); checkUpcOrBinIdMatch(e)} className="flex flex-row justify-center gap-3"> */}
          {host === "pda.ishtari.com.gh" && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                checkUpcOrBinIdMatch(e);
              }}
              className="flex flex-row justify-center gap-3"
            >
              <div className="relative flex-1">
                <input
                  onChange={handleInputChange}
                  ref={currentUpc}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                  placeholder="Current Upc"
                />
                <div>
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20 "
                  />
                </div>
              </div>
              <button className=" bg-dbase rounded-md text-white px-5 ">
                {" "}
                {loadingCheck ? <Loader /> : <FaSearch />}{" "}
              </button>
            </form>
          )}

          <form onSubmit={(e) => restockUpc(e)} className="flex flex-col gap-5">
            <div className="relative flex-1">
              <input
                required
                ref={restockQty}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Restock Quantity"
              />
            </div>
            <div className="relative flex-1">
              <input
                ref={commentRef}
                type="text"
                className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2"
                placeholder="Comment"
              />
            </div>
            <button
             
              type="submit"
              className=" bg-dbase  px-3 py-3 text-white rounded-sm mt-3"
            >
              {" "}
              {loading ? "Loading..." : "Confirm Restock"}
            </button>
          </form>
        </div>

        {productInf && (
          <div className="container">
            <div className="bg-white rounded-md shadow-md w-full px3 py-2 mt-10 ">
              <div className=" flex flex-row gap-5">
                <img src={productInf.image} alt="" className="w-48 h-w-48" />
                <div className=" my-auto">
                  <div className=" flex flex-col justify-center">
                    <h2>SKU: {productInf.sku}</h2>
                    <h2>Qty: {productInf.quantity}</h2>
                    <h2>Address:{productInf.address}</h2>
                    {productInf.bin_quantity && (
                      <h2>Bin Qty:{productInf.bin_quantity}</h2>
                    )}
                    {productInf.upc_quantity && (
                      <h2>Upc Qty:{productInf.upc_quantity}</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};

export default hocStarredPage(UpcRestockv2);
