import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaSearch,
  FaTrash,
  FaPaperPlane,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import buildLink from "../../../urls";
import { CompanyContext } from "../../../contexts/companyCotext";
import { IoIosWarning } from "react-icons/io";
import Loader from "../../../components/loader";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";
import Select from "react-select";
import hocStarredPage from "../../../components/hocStarredPage";
import Cookies from "js-cookie";

const ChangeReference = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productInf, setProductInf] = useState("");
  const [stateCom, dispatchCom] = useContext(CompanyContext);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [warehouses, setWarehouses] = useState([]);
  const oldReference = useRef(null);
  const newReference = useRef(null);
  const [OldReference, setOldReference] = useState("");
  const [error, setError] = useState(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [FromWarehouse, setFromWarehouse] = useState([]);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
  const OrderID = useRef(null);
  const ProductID = useRef(null);
  const [systemQuantity, setSystemQuantity] = useState(null);
  const [ProductInfo, setProductInfo] = useState([]);
  const [wareHouseStatus, setWareHouseStatus] = useState("");
  const [fromwarehouse, setfromwarehouse] = useState(null);
  const [towarehouse, settowarehouse] = useState(null);

  const productID = useRef(null);
  const { playErrorSound, playSuccessSound } = PlaySounds();

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();

    const savedFromWarehouse = Cookies.get("fromWarehouse");
    const savedToWarehouse = Cookies.get("toWarehouse");

    if (savedFromWarehouse) {
      setSelectedWarehousefrom(JSON.parse(savedFromWarehouse));
    }
  }, []);

  const handleFromWarehouseChange = (selectedOption) => {
    setSelectedWarehousefrom(selectedOption);

    Cookies.set("fromWarehouse", JSON.stringify(selectedOption), {
      expires: 1,
    });

    fetchtowarehouse(selectedOption);
  };

  const fetchtowarehouse = async (e) => {
    const warehouseId = e.value;
    setSelectedWarehousefrom(warehouseId);

    try {
      const url = buildLink(`towarehouse`) + `&warehouse_id=${warehouseId}`;
      const response = await axios.get(url);

      if (response.data.success) {
        setLoadingWarehouses(false);
        setFromWarehouse(response.data.data || []);
      } else {
        setError("Failed to fetch from warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch from warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // searchProduct

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async function changeShelfReference(e) {
    e.preventDefault();
    setLoading(true);
    const new_Reference = newReference.current.value;
    const old_Reference = oldReference.current.value;

    const obj = JSON.stringify({
      old_Reference,
      new_Reference,
    });

    try {
      const url = buildLink("updateReference") + `&v2=true`;
      await axios.put(url, JSON.parse(obj)).then((response) => {
        if (response.data.success) {
          playSuccessSound();
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          oldReference.current.value = "";
          newReference.current.value = "";
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  async function changeReference(e) {
    e.preventDefault();
    const userID = getUserIdFromLocalStorage();
    setLoading(true);
    const product_id = productID.current.value;
    const reference = newReference.current.value;
    const user_id = userID;
    const warehouse_id = towarehouse;
    const obj = JSON.stringify({
      product_id,
      reference,
      warehouse_id,
      user_id,
    });

    try {
      const url = buildLink("updateReference") + `&v2=true`;
      await axios.post(url, JSON.parse(obj)).then((response) => {
        if (response.data && response.data.success) {
          playSuccessSound();
          setMessage(response.data.message);
          setShowMessageSucc(true);
          setTimeout(() => {
            setShowMessageSucc(false);
          }, 3000);
          oldReference.current.value = "";
          newReference.current.value = "";
          productID.current.value = "";
        }
      });
    } catch (e) {
      playErrorSound();
      setMessage(
        e.response ? e.response.data.message : "Unknown error occurred"
      );
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoading(false);
  }

  const getWarehouseProductDetails = async (e) => {
    e.preventDefault(); // Prevent the page from refreshing
    setLoadingSearch(true);
    const item = productID.current.value; // Get the product ID

    const url =
      buildLink("getWarehouseProductDetails") +
      `&product=${item}` +
      `&warehouse_id=${towarehouse}`;

    try {
      const response = await axios.get(url);
      playSuccessSound();
      setMessage(response.data.message);
      setShowMessageSucc(true);
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
      setOldReference(response.data.product.upc);
    } catch (error) {
      playErrorSound();
      setLoadingSearch(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setMessage(error.response.data.message);
      }

      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } finally {
      setLoadingSearch(false);
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const history = useNavigate();

  const searchProduct = async (e) => {
    e.preventDefault();
    setLoadingSearch(true);
    const item = productID.current.value;

    const url =
      buildLink("productInfo") +
      `&item=${item}&v2=true` +
      `&user_id=${stateCom.userID}`;

    try {
      const response = await axios.get(url);
      playSuccessSound();
      setMessage(response.data.message);
      setShowMessageSucc(true);
      setTimeout(() => {
        setShowMessageSucc(false);
      }, 3000);
      setProductInf(response.data.data);
      newReference.current.focus();
    } catch (error) {
      playErrorSound();
      setLoadingSearch(false);

      // Set error message if the response contains a message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setMessage(error.response.data.message);
      }

      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleTrashClick = () => {
    productID.current.value = "";
    productID.current.focus();
  };

  const handleReferenceChange = (e) => {
    setOldReference(e.target.value);
  };


  return (
    <>
      <div className=" h-full bg-white">
        {/* header */}
        <div className=" mb-5 sticky top-0 flex flex-col bg-white">
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className=" text-xl my-auto">Change Reference</h2>
            </div>
          </div>
        </div>

        {/* <div className=" w-screen  bg-white my-4 container ">
          <div className=" flex w-full justify-center gap-2">
            <button
              onClick={() => setActiveTab(1)}
              className={`${
                activeTab === 1 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2  rounded-full`}
            >
              {" "}
              Single Product
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`${
                activeTab === 2 ? "bg-dbase text-white" : "bg-white text-dblack"
              }  transition-all w-full py-2 rounded-full`}
            >
              {" "}
              Shelf Products
            </button>
          </div>
        </div> */}
        {/* {activeTab === 1 && ( */}
        <>
          <div className="p-3">
            <div className="mb-1">Warehouse</div>
            {loadingWarehouses ? (
              <div className="text-center text-dbase">
                <Loader />
              </div>
            ) : error ? (
              <p className="text-dbase">{error}</p>
            ) : (
              <Select
                placeholder="To Warehouse"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    padding: "8px 5px",
                    height: "48px",
                  }),
                }}
                options={warehouses
                  .filter((warehouse) => warehouse.warehouse_id !== "13")
                  .map((warehouse) => ({
                    value: warehouse.warehouse_id,
                    label: warehouse.warehouse_name,
                  }))}
                value={warehouses
                  .map((warehouse) => ({
                    value: warehouse.warehouse_id,
                    label: warehouse.warehouse_name,
                  }))
                  .find((option) => option.value === towarehouse)}
                onChange={(option) => {
                  settowarehouse(option.value);
                }}
              />
            )}
          </div>
          <form onSubmit={(e) => getWarehouseProductDetails(e)} className="p-3">
            <div className="flex flex-col lg:px-1  ">
              <div className="mb-1">Product ID or SKU</div>
              <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3">
                <div className=" relative flex flex-row border-dblack rounded-lg text-center w-full gap-3">
                  <input
                    ref={productID}
                    type="text"
                    className="border border-dblack rounded-lg block p-2 flex-grow text-start w-full h-12 pr-10" // Add pr-10 to create space for the icon
                    placeholder="Product ID or SKU"
                  />
                  <FaTrash
                    onClick={() => handleTrashClick()}
                    className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                  />
                </div>

                <button className="border border-dblack bg-dbase rounded-lg text-white px-5 h-12">
                  {loadingSearch ? <Loader /> : <FaSearch />}
                </button>
              </div>
            </div>
          </form>
          <div className="p-3">
            <span >
            Old Reference
            <input
              ref={newReference}
              value={OldReference}
              onChange={handleReferenceChange}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 mb-3 pr-8  pl-2 "
              placeholder="Old Reference"
              required
            />
          </span>

          <span >
            New Reference
            <input
              ref={newReference}
              type="text"
              className="border border-dlabelColor outline-dbase w-full rounded-md  py-3 pr-8  pl-2 "
              placeholder="New Reference"
              required
            />
          </span>
          </div>
          
          <form className="container relative flex-1">
            <div className="w-full ">
              <button
                onClick={(e) => changeReference(e)}
                type="submit"
                className="w-full bg-dbase text-white flex justify-center items-center p-2 rounded-md mt-4 shadow"
              >
                {loading ? <Loader /> : <FaPaperPlane />}
              </button>
            </div>
          </form>
          <div className="container rounded-md shadow w-full">
            {productInf && (
              <div className="flex gap-3 lg:gap-4 p-4 mt-8">
                <img
                  className="object-cover w-40 h-40"
                  src={productInf.image}
                  alt="Product Image"
                />
                <div className="flex flex-col ">
                  <div className="my-2">
                    <span className="font-bold">SKU:</span> {productInf.sku}
                  </div>
                  <div className="my-2">
                    <span className="font-bold">Quantity:</span>{" "}
                    {`${productInf ? productInf.quantity : ""}`}
                  </div>
                  <div>
                    <span className="font-bold">
                      Address: {productInf.address}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
        {/* )} */}
        {/* {activeTab === 2 && (
          <>
            <form
              onSubmit={(e) => changeShelfReference(e)}
              className="container relative flex-1"
            >
              <span>
                Old Reference
                <input
                  required
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8 pl-2 mb-4"
                  placeholder="Old Reference"
                  ref={oldReference}
                />
              </span>
              New Reference
              <span>
                <input
                  required
                  ref={newReference}
                  type="text"
                  className="border border-dlabelColor outline-dbase w-full rounded-md  py-2 pr-8  pl-2 "
                  placeholder="New Reference"
                />
              </span>
              <div className="w-full container">
                <button
                  type="submit"
                  className="w-full bg-dbase text-white p-2 rounded-md mt-4 shadow"
                >
                  {loading ? "Loading..." : "Change Reference"}
                </button>
              </div>
            </form>
          </>
        )} */}

        <MessageAlert
          message={message}
          type={showMessgErr ? "err" : "succ"}
          showMessg={showMessgErr || (showMessgSucc && true)}
        />
      </div>
    </>
  );
};
export default hocStarredPage(ChangeReference);
