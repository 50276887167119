import React from "react";

const SingleProduct = (props) => {
  const decodedName = decodeURIComponent(props.item.name);

  return (
    <div className="relative">
      <div className="flex flex-col h-full md:w-unset duration-300 ease-out translate-y-0 transition-all bg-white text-dblack p-2 relative ">
        <div className="flex flex-col h-ful">
          <div className="h-full relative flex gap-3">
            <div className="relative ">
              <img
                className={`transition-all  ease-out my-auto duration-500 `}
                src={props.item.image}
                width={180}
                height={167}
              />
            </div>
            <div className="product-info flex flex-col w-full">
            <div>
              <div className="product-name text-d14 font-semibold leading-spn h-9 overflow-hidden mt-3">
                <span className="text-sm">{decodedName}</span>
              </div>
              <div className="my-1 mt-2">
                <div className={` text-d13  font-normal`}>
                  <span className="text-black font-bold text-sm">SKU: </span>
                  <span className="text-sm">{props.item.sku}</span>
                </div>
                <div className={` text-d13  font-normal`}>
                  <span className="text-black font-bold text-sm mr-1">
                    Transfered Quantity:
                  </span>
                  <span className="text-sm">
                    {props.item.transfered_quantity}
                  </span>
                </div>
                <div className={`text-d13 font-normal flex`}>
                  <span className="text-black font-bold mr-1 text-sm">
                    Quantity:
                  </span>
                  <span className="text-sm">{props.item.quantity}</span>
                </div>
                <div className={`text-d13 font-normal flex`}>
                  <span className="text-black font-bold mr-1 text-sm">
                    Status:
                  </span>
                  <span className="text-sm">{props.item.status}</span>
                </div>
              </div>
            </div>
          </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
