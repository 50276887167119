import React, { useState, useEffect ,useRef} from "react";
import { useNavigate } from "react-router";
import { FaArrowLeft, FaPaperPlane, FaSearch ,FaTrash} from "react-icons/fa";
import Select from "react-select";
import Loader from "../../../components/loader";
import axios from "axios";
import Cookies from "js-cookie";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";

const Warehousetransfer = () => {
  const history = useNavigate();
  const [loadingProductSearch, setLoadingProductSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [destinationWarehouse, setDestinationWarehouse] = useState(null);
  const [inputQuantity, setInputQuantity] = useState("");
   const codePr = useRef(null);
  const [scanProductId, setScanProductId] = useState("");
  const [ProductInfo, setProductInfo] = useState([]);
  const [address, setAddress] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const [FromWarehouse, setFromWarehouse] = useState([]);
  const [addresstype,setSelectAddressType]=useState("bin");
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
   const [selectedtoWarehouseId, setSelectedToWarehouseId] = useState(null);
    const [selectedfromWarehouseId, setSelectedFromWarehouseId] = useState(null);
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [logEntries, setLogEntries] = useState([]);
  const [BinId, setBinId] = useState("");
  const [Sku,setSku] = useState("");
  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();

    const savedFromWarehouse = Cookies.get("fromWarehouse");
    const savedToWarehouse = Cookies.get("toWarehouse");

    if (savedFromWarehouse) {
      setSelectedFromWarehouseId(JSON.parse(savedFromWarehouse));
    }
    if (savedToWarehouse) {
      setSelectedToWarehouseId(JSON.parse(savedToWarehouse));
    }
  }, []);

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const handleFromWarehouseChange = (selectedOption) => {
      const warehouseId = selectedOption ? selectedOption.value : null;
      setSelectedFromWarehouseId(warehouseId);
  
      // Save to cookies for 1 day
      if (warehouseId) {
        Cookies.set("selectedWarehousecheckbin", warehouseId, { expires: 1 });
      } else {
        Cookies.remove("selectedWarehousecheckbin");
      }
    };
    const handleToWarehouseChange = (selectedOption) => {
        const warehouseId = selectedOption ? selectedOption.value : null;
        setSelectedToWarehouseId(warehouseId);
    
        // Save to cookies for 1 day
        if (warehouseId) {
          Cookies.set("selectedWarehousecheckbin", warehouseId, { expires: 1 });
        } else {
          Cookies.remove("selectedWarehousecheckbin");
        }
      };



  const fetchtowarehouse = async (e) => {
    const warehouseId = e.value;
    setSelectedToWarehouseId(warehouseId);
    setSelectedFromWarehouseId(null);

    try {
      const url = buildLink(`towarehouse`) + `&warehouse_id=${warehouseId}`;
      const response = await axios.get(url);

      if (response.data.success) {
        setFromWarehouse(response.data.data || []);
      } else {
        setError("Failed to fetch from warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch from warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

 

  const submitwarehousechange = async () => {
    const UserId = getUserIdFromLocalStorage();
   const inputProductId=codePr.current.value;
    if (!inputProductId) {
      setMessage("Enter Product ID OR SKU");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 10000);
      return;
    }
    if (!BinId) {
      setMessage("Enter Address ID");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 10000);
      return;
    }
    if (inputQuantity<=0) {
      setMessage("Quantity Must Be greater than 0");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }
  
    const fromWarehouseId =
     selectedfromWarehouseId ||
      JSON.parse(Cookies.get("fromWarehouse"))?.value;
    const toWarehouseId =
      selectedtoWarehouseId ||
      JSON.parse(Cookies.get("toWarehouse"))?.value;
  
    if (!fromWarehouseId || !toWarehouseId) {
      setMessage("Please select both source and destination warehouses.");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 10000);
      return;
    }
  
    const obj = {
      from_warehouse: fromWarehouseId,
      to_warehouse: toWarehouseId,
      product_id: inputProductId,
      quantity: inputQuantity,
      user_id: UserId,
      bin_id: BinId,
      address_type:addresstype
    };
  
    try {
      const response = await axios.post(
        buildLink("submitwarehousechange"),
        obj
      );

      if (response.data.success) {
        setMessage(response.data.message);
        setShowMessageSucc(true);
        playSuccessSound();
        codePr.current.value="";
        setInputQuantity("");
        setFromWarehouse([]);
        setProductInfo([]);
        // Prepend log entry with SKU from the response
        const newLogEntry = {
          productId: inputProductId,
          quantity: inputQuantity,
          sku: response.data.sku,
        };
  
        setLogEntries((prevEntries) => [newLogEntry, ...prevEntries]);
  
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 10000);
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        playErrorSound();
        setTimeout(() => {
          setShowMessageErr(false);
        }, 10000);
        setProductInfo([]);
        setInputQuantity("");
        codePr.current.value="";
      }
    } catch (err) {
      console.log("An error occurred during the transfer.", err);
    }
  };
  
  const searchProduct = async (e) => {
    setLoadingProductSearch(true);
    e.preventDefault();

    const item = codePr.current.value;
    const warehouseID = selectedtoWarehouseId;// Get the warehouse ID from the state

    try {
      const url =
        buildLink(`getproductsinwarehouse`) +
        `&product_id=${item}`;
      const response = await axios.get(url);
      if (response.data.success) {
        setProductInfo(response.data.warehouses);
        setMessage(response.data.message);
        setShowMessageSucc(true);
        playSuccessSound();
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        playErrorSound();
        setProductInfo([]);
      }
    } catch (e) {
      playErrorSound();
      setLoadingProductSearch(false);
      setMessage(e.response.data.message);
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
    }
    setLoadingProductSearch(false);
  };

  const handleTrashClick = () => {
    codePr.current.value = "";
    codePr.current.focus();
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  return (
    <>
      {/* Header */}
      <div className="mb-5  top-0 flex flex-col bg-white z-50 overflow-scroll">
        <div className="w-full bg-dbase h-16">
          <div className="container px-3 relative flex justify-between w-full text-white h-full">
            <div className="flex gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Warehouse Transfer</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-5">
        <h3 className="text-lg font-semibold mb-3">Transfer Product</h3>
        <div className="flex flex-row gap-3 w-full" >
                          <div className="relative w-full">
                            <input
                              ref={codePr}
                              type="text"
                              className="border border-dlabelColor outline-dbase w-full rounded-md py-3 pr-8 pl-2"
                              placeholder="Product ID Or Sku"
                            
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  searchProduct(e)
                                }
                              }}/>
                            <div>
                              <FaTrash
                                onClick={() => handleTrashClick()}
                                className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer opacity-20"
                              />
                            </div>
                          </div>
                          <button className="bg-dbase rounded-md text-white px-5 py-3  flex items-center justify-center" onClick={(e) => searchProduct(e)}>
                            {loadingProductSearch ? <Loader /> : <FaSearch />}
                          </button>
                        </div>
                        {ProductInfo.length > 0 && (
              <div className="overflow-x-auto mt-5  md:px-12 lg:px-32">
                <table className="min-w-full bg-white border border-dgreySeller">
                  <thead>
                    <tr className="bg-dgrey text-dblackk">
                      <th className="border px-4 py-2">Warehouse Name</th>
                      <th className="border px-4 py-2">Quantity</th>
                      <th className="border px-4 py-2">Address</th>
                      <th className="border px-4 py-2">Reference</th>
                      <th className="border px-4 py-2">Pending Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                  {ProductInfo.map((warehouse, index) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="border px-4 py-2 text-center">
                          {warehouse.warehouse_name}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.quantity}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.address_id}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.reference}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {warehouse.pending_qty}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          <div className="flex flex-col">
            <div className="py-3">
              <div className="mb-1">From Warehouse</div>
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                placeholder="Select Warehouse"
                required
                onChange={handleFromWarehouseChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "7px",
                    borderColor: state.isFocused ? "grey" : "grey",
                    padding: "8px 5px",
                  }),
                }}
                options={
                  warehouses.length > 0
                    ? warehouses
                   
                    .map((warehouse) => ({
                        value: warehouse.warehouse_id,
                        label: warehouse.warehouse_name,
                      }))
                    : []
                }
                value={
                  selectedfromWarehouseId
                    ? warehouses
                   
                        .map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                        .find(
                          (option) => option.value === selectedfromWarehouseId
                        )
                    : null
                }
                className="w-full"
              />
              )}
            </div>
            <div className="py-3">
              <div className="mb-1">To Warehouse</div>
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                  placeholder="Select Warehouse"
                  required
                  onChange={handleToWarehouseChange}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                    }),
                  }}
                  options={
                    warehouses.length > 0
                      ? warehouses
                      .map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                      : []
                  }
                  value={
                    selectedtoWarehouseId
                      ? warehouses
                     
                          .map((warehouse) => ({
                            value: warehouse.warehouse_id,
                            label: warehouse.warehouse_name,
                          }))
                          .find(
                            (option) => option.value === selectedtoWarehouseId
                          )
                      : null
                  }
                  className="w-full"
                />
              )}
            </div>
     
           
          
        
            <div className="py-2">
  <div className="mb-1">Address Type</div>
  <Select
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: "7px",
        borderColor: state.isFocused ? "grey" : "grey",
        padding: "8px 5px",
      }),
    }}
    value={{ value: addresstype, label: addresstype.charAt(0).toUpperCase() + addresstype.slice(1) }}
    onChange={(selectedOption) => setSelectAddressType(selectedOption.value)}
    options={[
      { value: 'bin', label: 'Bin' },
      { value: 'reference', label: 'Reference' }
    ]}
    defaultValue={{ value: 'bin', label: 'Bin' }}
    placeholder="Select Warehouse"
    className="w-full"
  />
</div>


            <div className="py-3">
              <div className="mb-1">Address Id</div>
              <input
                type="text"
                value={BinId}
                onChange={(e) => setBinId(e.target.value)}
                placeholder="Address ID"
                className="border p-3 rounded-md w-full"
                required
              />
            </div>
       
            <div className="py-3">
              <div className="mb-1">Quantity</div>
              <input
                type="number"
                min={1}
                value={inputQuantity}
                onChange={(e) => setInputQuantity(e.target.value)}
                placeholder="Quantity to Transfer"
                className="border p-3 rounded-md w-full"
                required
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    submitwarehousechange();
                  }
                }}/>
            </div>

            <button
              type="submit"
              className="bg-dbase rounded-md text-white px-5 py-3 flex justify-center items-center"
             onClick={(e) => {
            e.preventDefault();
            submitwarehousechange();
          }}>
              {loadingSearch ? <Loader /> : <FaPaperPlane />}
            </button>
          </div>

      </div>

      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />

     

  
    </>
  );
};

export default Warehousetransfer;
